import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../services/API";
import { endpoint } from "../endpoint";
import { resetTokens, restartAuth, refreshToken } from "./authSlice";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type thirdPartyState = {
  loading: boolean;
  message: string | null;
  errors: string[];
  data: any | null;
  mamaMoneyReports: any | null;
  moneygramReports: any | null;
  senditooReports: any | null;
  terrapayReports: any | null;
  cancel: any | null;
  reverse: any | null;
};

const initialState: thirdPartyState = {
  loading: false,
  message: null,
  errors: [],
  data: null,
  mamaMoneyReports: null,
  moneygramReports: null,
  senditooReports: null,
  terrapayReports: null,
  reverse: null,
  cancel: null,
};

export const searchTransaction = handleAPI("mamamoney/searchTransaction", "get", `external/search/mama_money/:ref`);
export const searchSenditooTransaction = handleAPI(
  "senditoo/searchTransaction",
  "get",
  `external/search/senditoo/:ref`
);
export const getMamaMoneyReports = handleAPI(
  "mamamoney/reports",
  "post",
  `/reports/external/mama_money?perPage=200&page=1`
);
export const getSenditooReports = handleAPI(
  "senditoo/reports",
  "post",
  `/reports/external/senditoo?perPage=200&page=1`
);
export const getMoneyGramReports = handleAPI(
  "moneygram/reports",
  "post",
  `/reports/moneygram?perPage=200&page=1`
);
export const getTerrapayReposts = handleAPI("terrapay/reports", "post", `/reports/terrapay?perPage=20&page=1`);
export const processTerrapayCancel = handleAPI("/terrapay/cancel", "post", `/terrapay/cancel/:reason/:tpRef`);
export const processTerrapayReverse = handleAPI("/terrapay/reverse", "post", `/terrapay/reverse/:reason/:tpRef`);
export const processTransaction = handleAPI("external/processTransaction", "post", `external/process`);

export const thirdPartySlice = createSlice({
  name: "thirdParty",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = [];
    },
    clearSearchTransaction: (state) => {
      state.loading = false;
      state.data = null;
      state.message = null;
      state.errors = [];
    },
    clearReports: (state) => {
      state.loading = false;
      state.mamaMoneyReports = null;
      state.senditooReports = null;
      state.moneygramReports = null;
      state.terrapayReports = null;
      state.message = null;
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(searchTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.data = payload?.response ?? null;
    });
    builder.addCase(searchTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(searchSenditooTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(searchSenditooTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.data = payload?.response ?? null;
    });
    builder.addCase(searchSenditooTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(getMamaMoneyReports.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.mamaMoneyReports = null;
    });
    builder.addCase(getMamaMoneyReports.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.mamaMoneyReports = payload?.response ?? null;
    });
    builder.addCase(getMamaMoneyReports.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    builder.addCase(getMoneyGramReports.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.moneygramReports = null;
    });
    builder.addCase(getMoneyGramReports.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.moneygramReports = payload?.response ?? null;
    });
    builder.addCase(getMoneyGramReports.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(getSenditooReports.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.senditooReports = null;
    });
    builder.addCase(getSenditooReports.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.senditooReports = payload?.response ?? null;
    });
    builder.addCase(getSenditooReports.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(getTerrapayReposts.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.terrapayReports = null;
    });
    builder.addCase(getTerrapayReposts.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.terrapayReports = payload?.response ?? null;
    });
    builder.addCase(getTerrapayReposts.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(processTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(processTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = "Transaction Processed Successfully";
    });
    builder.addCase(processTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(processTerrapayCancel.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.reverse = null;
    });
    builder.addCase(processTerrapayCancel.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.reverse = "Transaction Processed Successfully";
    });
    builder.addCase(processTerrapayCancel.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(processTerrapayReverse.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.cancel = null;
    });
    builder.addCase(processTerrapayReverse.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.cancel = "Transaction Processed Successfully";
    });
    builder.addCase(processTerrapayReverse.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
  },
});

//Export actions
export const { clearSearchTransaction, clearReports, clearErrors } = thirdPartySlice.actions;

export default thirdPartySlice.reducer;
